.feedback-layout {
  padding: 0;
}

.feedback-layout .card {
  background: #f8faff;
  border: 4px solid #98b6f1;
  box-shadow: 0 174px 70px rgba(0, 0, 0, 0.01), 0 98px 59px rgba(0, 0, 0, 0.05),
    0 44px 44px rgba(0, 0, 0, 0.09), 0 11px 24px rgba(0, 0, 0, 0.1),
    0 0 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  box-sizing: border-box;

  min-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 44px;
  max-width: 450px;
}

.feedback-layout .card.card-ok {
  padding: 148px 50px 116px;
}

@media screen and (max-width: 480px) {
  .feedback-layout .card {
    border: none;
    border-radius: 0;
    width: 100%;
    min-height: 100vh;
    background: transparent;
    padding: 48px 16px;

    max-height: 100vh;
    display: grid;
    grid-template-rows: minmax(max-content, 100vw) max-content max-content;
    align-items: start;
  }

  .feedback-layout .card .card__image-wrap {
    position: relative;
    display: flex;
    height: 100%;
  }

  .feedback-layout .card .card__image {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }

  .feedback-layout .card.card-ok {
    padding: 16px;
    justify-content: center;
  }
}
