.feedback-page {
    display: flex;
    align-self: center;
    flex: 1;
    justify-content: center;
}

.feedback-page .link {
    text-decoration: none;
}

.feedback-page .button {
    background: #FFFFFF;
    border: 2px solid;
    border-radius: 24px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: none;
    padding: 12px 16px;
    text-decoration: none;
}

.feedback-page .button.success {
    border-color: #009100;
    color: #009100;
}

.feedback-page .button.success:hover {
    background: #FFFFFF;
    border: 2px solid #02B602;
    color: #02B602;
}

.feedback-page .button.MuiButton-contained.success {
    background-color: #009100;
    color: #FFFFFF;
}

.feedback-page .button.MuiButton-contained.success:hover {
    border-color: #02B602;
    background-color: #02B602;
    color: #FFFFFF;
}

.feedback-page .button.error {
    border-color: #FF584E;
    color: #FF584E;
}

.feedback-page .button.error:hover {
    background: #FFFFFF;
    border: 2px solid #FF9B95;
    color: #FF9B95;
}

.feedback-page .button:disabled {
    opacity: 0.5;
}

@media screen and (max-width: 480px) {
    .feedback-page .link {
        flex: 1;
    }

    .feedback-page .button {
        white-space: nowrap;
        flex: 1;
    }
}